<template>
  <div class="tl-stats-top-ten">
    <div class="d-flex justify-end align-end caption">
      <div class="tl-stats-top-ten__col mr-4" v-t="'dashboard.score.effective'" />
      <div class="bonus mr-2 text-center" @click="explainBonus">
        <v-icon :size="14">tl-info</v-icon><br />
        {{ $t('dashboard.score.bonus') }}
      </div>
      <div class="tl-stats-top-ten__col text-no-wrap" v-t="'dashboard.score.points'" />
    </div>
    <div
      v-for="(log, index) in logsFormatted"
      :key="index"
      class="d-flex align-center tl-stats-top-ten__row py-1 px-2"
      v-ripple
      @click="goToClimb(log.climb_id)"
    >
      <div class="tl-stats-top-ten__col--grade text-no-wrap">
        <tl-grade-circle class="d-inline-block mr-1" :color="log.color" :color-seconday="log.colorSeconday" size="10" />
        <span v-html="log.gradeHtml" />
      </div>
      <div class="mr-auto text-truncate">{{ log.name }}</div>
      <div class="tl-stats-top-ten__col mr-4">{{ log.daysLeft }}</div>
      <div class="tl-stats-top-ten__col mr-2 caption">{{ log.bonus }}</div>
      <div class="tl-stats-top-ten__col">{{ log.points }}</div>
    </div>
    <v-divider />
    <div class="d-flex justify-end font-weight-bold white--text px-2">
      <div class="tl-stats-top-ten__col mr-2" v-t="'dashboard.score.average'" />
      <div class="tl-stats-top-ten__col">{{ averageRounded }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { round } from '@/services/utils'
import Grade from '@/services/grades/Grade'
import tlGradeCircle from '@/components/gym/climbs/tl-grade-circle'

export default {
  components: {
    tlGradeCircle,
  },
  props: {
    logs: { type: Array, default: () => [] },
    average: { type: Number, default: 0.0 },
  },
  computed: {
    ...mapState(['climbType']),
    logsAppended() {
      return this.logs.concat(new Array(10 - this.logs.length).fill({}))
    },
    logsFormatted() {
      return this.logsAppended.map(log => {
        return {
          climb_id: log.climb_id,
          color: log.color,
          colorSecondary: log.color_secondary,
          gradeHtml: new Grade(log.grade).nameStripped,
          name: log.name || log.wall_name,
          daysLeft: this.getDaysLeft(log.date_logged),
          bonus: this.getBonusPoints(log.checks),
          points: round(log.grade_adj * 100) || '0',
        }
      })
    },
    averageRounded() {
      return round(this.average * 100) || 0
    },
  },
  methods: {
    getDaysLeft(date) {
      if (!date) return ''
      let days = -this.moment().subtract(2, 'month').diff(date, 'days') // eslint-disable-line
      return this.$vuetify.breakpoint.smAndUp ? `${days} days` : `${days}d`
    },
    getBonusPoints(checks) {
      if (!checks || checks <= 1) return ''
      return `+${round(((checks - 1) * 100) / 6)}`
    },
    explainBonus() {
      let title = this.$t('dashboard.score.bonusExplHeader')
      let text = this.climbType == 'route' ? this.$t('dashboard.score.bonusExplOS') + '<br /><br />' : ''
      text = text + this.$t('dashboard.score.bonusExplFL')
      this.$store.dispatch('dialog/alert', { title, text })
    },
    goToClimb(climbId) {
      let name = climbId ? 'climbs.climb' : 'climbs'
      let climbsType = this.climbType + 's'
      this.$router.push({ name, params: { climbId, climbsType } })
    },
  },
}
</script>

<style lang="sass">
.tl-stats-top-ten
  &__row
    cursor: pointer
    &:hover
      opacity: 0.7
    &:nth-child(even)
      background: #363636
      border-radius: 4px
  &__col
    text-align: right
    min-width: 30px
  &__col--grade
    min-width: 50px
</style>
