<template>
  <div class="tl-stats-grade-bar" @click="showGradesChart">
    <span class="bar pre"></span>
    <div class="bar center">
      <div class="bar progress" :style="{ width: percentage + '%' }"></div>
      <span class="points" :style="progressPosition" v-if="percentage">
        {{ gradeInstance.points }}
      </span>
      <span class="grade percent" :style="progressPosition" v-if="percentage">{{ percentage }}%</span>
    </div>
    <span class="points floor">{{ gradeInstance.pointsFloor }}</span>
    <span class="points ceil">{{ gradeInstance.pointsCeil }}</span>
    <v-icon class="explain-points md-18">tl-info</v-icon>
    <span class="grade floor">{{ gradeInstance.nameFloor }}</span>
    <span class="grade ceil">{{ gradeInstance.nameCeil }}</span>
    <v-tooltip>
      {{ $t('dashboard.score.gradeBarTooltip', { percentLeft: gradeInstance.percentToNextGrade }) }}
      <span>{{ gradeInstance.nameCeil }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Grade from '@/services/grades/Grade'
import tlGradesChartDialogMixin from '@/components/shared/tl-grades-chart/tl-grades-chart.dialog.mixin'

export default {
  mixins: [tlGradesChartDialogMixin],
  props: {
    grade: { type: Number, default: 6.05 },
  },
  computed: {
    ...mapState(['climbType']),
    gradeInstance() {
      let grade = this.grade < 2.0 ? 2.0 : this.grade
      return new Grade(grade)
    },
    percentage() {
      return this.gradeInstance.subGradePercent
    },
    progressPosition() {
      if (this.percentage < 15) return { left: '25px' }
      if (this.percentage > 85) return { right: '25px' }
      return { left: this.percentage + '%', transform: 'translateX(-50%)' }
    },
  },
  methods: {
    showGradesChart() {
      this.showGradesChartDialog(this.climbType, this.grade)
    },
  },
}
</script>

<style lang="sass" scoped>
$radius: 4px

.tl-stats-grade-bar
  position: relative
  cursor: pointer
  width: 100%
  height: 40px
  margin: 30px 0
  background-color: var(--v-primary-lighten4)
  border-radius: $radius
  &:hover
    background-color: var(--v-primary-lighten3)
    .pre,
    .progress
      background-color: var(--v-primary-darken1)
  *
    position: absolute
  .bar
    height: 100%
  .pre,
  .progress
    background-color: var(--v-primary-base)
  .pre
    width: 10%
    border-top-left-radius: $radius
    border-bottom-left-radius: $radius
  .center
    left: 10%
    width: 80%
    border-left: 2px solid white
    border-right: 2px solid white
    font-weight: bold
    color: white
  .floor
    left: 10%
    transform: translateX(-50%)
  .ceil
    right: 10%
    transform: translateX(50%)
  .points
    top: -1.8em
  .grade
    bottom: -1.8em
  .explain-points
    right: 90%
    top: 50%
    transform: translate(-50%, -50%)
    min-height: 16px
    width: 16px
    min-width: 16px
    height: 16px
</style>
