<template>
  <v-card max-width="600" class="mx-auto">
    <v-card-title class="d-block text-center">
      {{ $t('dashboard.ranking.header', { gymName: gym.name }) }}
    </v-card-title>
    <v-img :src="require('@/components/shared/tl-ranking/ranking-banner.png')" max-height="200px" contain />

    <tl-ranking
      :users="rankedUsers"
      :user="rankedUser"
      :genders="genders"
      :gender.sync="gender"
      :poules="poules"
      :poule.sync="poule"
      :loading="loading"
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @update:user="showUser($event)"
    >
      <template v-slot:default="{ user }">
        <v-list-item-title v-text="user.full_name" />
        <v-list-item-subtitle v-if="user.score_count && user.score_count < 10">
          {{ $t('dashboard.ranking.tooFewLogsMsg', { count: user.score_count }) }}
        </v-list-item-subtitle>
      </template>
      <template v-slot:score="{ user }">
        {{ getScore(user.score) }}
      </template>
    </tl-ranking>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import User from '@/models/User'
import Gym from '@/models/Gym'
import Grade from '@/services/grades/Grade'
import tlRanking from '@/components/shared/tl-ranking/tl-ranking'

export default {
  components: {
    tlRanking,
  },
  props: {
    user: { type: User, default: null },
  },
  data: () => ({
    loading: false,
    rankedUsers: [],
    genders: ['unisex', 'male', 'female'],
    gender: null,
    poule: null,
    page: null,
    itemsPerPage: 30,
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    rankedUser() {
      if (!this.user) return null
      return this.rankedUsers.find(u => u.uid == this.user.uid)
    },
    poules() {
      return ['grade', 'count'].map(type => ({
        id: type,
        name: this.$t(`dashboard.ranking.${type}Header`),
        description: this.$t(`dashboard.ranking.${type}Subhead`, { gymName: this.gym.name }),
      }))
    },
    rankingType: {
      get() {
        return this.poule && this.poule.id
      },
      set(type) {
        this.poule = this.poules.find(p => p.id == type) || this.poules[0]
      },
    },
    fetchParams() {
      return {
        gym_id: this.gym.id,
        climbs_type: this.climbType + 's',
        ranking_type: this.rankingType,
        gender: this.gender != 'unisex' ? this.gender : null,
        page: this.page,
        itemsPerPage: this.itemsPerPage,
      }
    },
  },
  watch: {
    fetchParams() {
      this.fetch() // The ranking will set the poule, triggering the initial fetch:
    },
  },
  methods: {
    fetch() {
      this.loading = true
      // Capture fetchParams in functionScope:
      let onSuccess = (() => {
        const gender = this.fetchParams.gender || 'unisex'
        const rankingType = this.fetchParams.ranking_type
        return users => {
          this.rankedUsers = this.addRanks(users)
          this.gender = gender
          this.rankingType = rankingType
        }
      })()
      Gym.$apiCall('rankedUsers', { params: this.fetchParams, timeout: 0 })
        .then(onSuccess)
        .finally(() => (this.loading = false))
    },
    addRanks(users) {
      return users
        .sort((a, b) => b.score - a.score || a.full_name.localeCompare(b.full_name))
        .map((user, i) => {
          const prevUser = users[i - 1] || { rank: 1 }
          user.rank = user.score == prevUser.score ? prevUser.rank : i + 1
          return user
        })
    },
    showUser(user) {
      if (!user) return // Already showing this user. Deselecting emits null.
      this.$router.pushSmart({ query: { uid: user.uid } })
    },
    getScore(score) {
      if (this.rankingType == 'count') return score
      let grade = new Grade(score)
      return `${grade.nameFloor} +${grade.subGradePercent}%`
    },
  },
}
</script>
