<template>
  <v-container class="py-0">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="9">
        <tl-user-stats v-if="userViewed" :user="userViewed" :loading="userViewedLoading" class="mb-4" />
        <tl-climb-renewals />
      </v-col>
      <v-col cols="12">
        <v-lazy>
          <tl-rankings :user="userViewed" />
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import uidMixin from '../uid.mixin'
import multiguard from 'vue-router-multiguard'
import uidGuard from '../uid.guard'
import climbsTypeGuard from '@/components/gym/climbs/climbs-type.guard'
import tlShareBtn from '@/components/shared/tl-share-btn'
import tlUserStats from './user-stats/tl-user-stats'
import tlClimbRenewals from './climb-renewals/tl-climb-renewals'
import tlRankings from './tl-rankings'
import { combineRoutes } from '@/services/utils'
import { mapState, mapGetters } from 'vuex'

export default {
  mixins: [toolbarMixin, uidMixin],
  components: {
    tlUserStats,
    tlClimbRenewals,
    tlRankings,
  },
  head() {
    if (!this.$i18n) return {} // Some weired bug when re-visiting a page.
    let description = this.$t('dashboard.description', { gymName: this.gym.name })
    return {
      title: this.$t('dashboard.title', { gymName: this.gym.name }),
      meta: [{ vmid: 'description', name: 'description', content: description }],
    }
  },
  computed: {
    ...mapState(['gym', 'user', 'climbType']),
    ...mapGetters(['userViewed']),
    tlToolbarTitle() {
      return this.$t('sidenav.dashboard')
    },
    tlToolbarTabs() {
      if (!this.gym.nr_of_routes || !this.gym.nr_of_boulders) return []
      let getTab = climbsType => ({
        name: this.$t(`generic.${climbsType}`),
        to: combineRoutes(this.$route, { params: { climbsType } }),
      })
      return ['routes', 'boulders'].map(getTab)
    },
    tlToolbarRightComp() {
      return {
        component: tlShareBtn,
        props: { msg: 'Check out what I climbed on TopLogger!' },
      }
    },
    notYouBanner() {
      return {
        id: 'userViewed',
        icon: 'tl-target-account',
        text: this.$t('dashboard.otherUserMsg', { userName: this.userViewed && this.userViewed.fullName }),
        btns: [
          {
            text: this.$t('dashboard.viewClimbs', { routesBoulders: this.$t(`generic.${this.climbType}s`) }),
            action: () => {
              this.$router.pushSmart({ name: 'climbs', params: { climbsType: this.climbType + 's' } })
            },
          },
          {
            text: this.$t('dashboard.showMine'),
            action: () => {
              this.$router.pushSmart({ name: 'dashboard', query: { uid: undefined } })
            },
          },
        ],
      }
    },
  },
  beforeRouteEnter: multiguard([uidGuard, climbsTypeGuard]),
  beforeRouteUpdate: multiguard([uidGuard, climbsTypeGuard]),
  watch: {
    notYouBanner: {
      immediate: true,
      handler() {
        this.updateNotYouBanner()
      },
    },
  },
  methods: {
    updateNotYouBanner() {
      if (!this.userViewed || this.userViewed == this.user)
        return this.$store.commit('toolbar/removeIdBanner', 'userViewed')
      this.$store.commit('toolbar/setIdBanner', this.notYouBanner)
    },
  },
}
</script>
