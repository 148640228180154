<template>
  <div class="tl-graph-plot-container" ref="container" v-resize="updateWidth" :style="{ height: `${height}px` }">
    <v-overlay v-if="loading" absolute>
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
    <svg v-else-if="data.length" ref="svg" :width="width" :height="height">
      <defs>
        <linearGradient v-for="grade in data" :key="grade.key" :id="`grdnt-${grade.key}`" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0" :stop-color="grade.color" stop-opacity="0.3" />
          <stop offset="0" :stop-color="grade.color" stop-opacity="0">
            <animate attributeName="offset" from="0" to="1" begin=".6s" dur=".6s" fill="freeze" />
          </stop>
        </linearGradient>
      </defs>
      <g class="chart" :transform="`translate(${margin.left},${margin.top})`">
        <g v-for="grade in data" :key="grade.key">
          <transition tag="g" appear name="line">
            <path class="line" :d="line(grade.values)" :stroke="grade.color" />
          </transition>
          <path
            v-if="grade.key != 'adjusted_grade'"
            class="fill"
            :d="area(grade.values)"
            :fill="`url(${currentUrl}#grdnt-${grade.key})`"
          />
        </g>
        <g class="axis axis__x" v-axis:x="{ scale }" :transform="`translate(0,${heightChart})`"></g>
        <g class="axis axis__y" v-axis:y="{ scale, tickValues, tickFormat }"></g>
        <g
          class="grid"
          v-axis:y="{ scale, tickValues: tickValuesGrid, tickFormat: tickFormatGrid, tickSize: tickSizeGrid }"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import d3 from '@/services/d3-stripped'
import { mapState } from 'vuex'
import Grade from '@/services/grades/Grade'
import { round } from '@/services/utils'

export default {
  props: {
    data: { type: Array, default: () => [] },
    loading: { type: Boolean, default: () => true },
  },
  data: () => ({
    width: 0,
    margin: {
      top: 10,
      right: 15,
      bottom: 70,
      left: 50,
    },
  }),
  computed: {
    ...mapState(['climbType']),
    height() {
      return Math.max(round((this.width * 2) / 5), 300)
    },
    heightChart() {
      return this.height - this.margin.top - this.margin.bottom
    },
    widthChart() {
      return this.width - this.margin.left - this.margin.right
    },
    domain() {
      const x = !this.data.length // eslint-disable-line
        ? [new Date(), new Date()]
        : d3.extent(this.data[0].values, g => g.date)
      const y = [ // eslint-disable-line
        d3.min(this.data, g => g.minVal),
        d3.max(this.data, g => g.maxVal),
      ]
      return { x, y }
    },
    scale() {
      const x = d3
        .scaleTime()
        .domain(this.domain.x)
        .range([0, this.widthChart])
      const y = d3
        .scaleLinear()
        .domain(this.domain.y)
        .range([this.heightChart, 0])
      return { x, y }
    },
    tickValues() {
      let yReadable = this.tickValuesGrid.y
      if (yReadable.length > 10) {
        yReadable = yReadable.filter((val, i) => i % 2)
      }
      return { x: null, y: yReadable }
    },
    tickValuesGrid() {
      const [yMin, yMax] = this.domain.y
      const y = Grade.system(this.climbType)
        .data.map(g => g.value)
        .filter(v => v >= yMin && v <= yMax)
      return { x: null, y }
    },
    tickFormat() {
      const x = null
      const y = d => new Grade(d).nameOrQStripped
      return { x, y }
    },
    tickFormatGrid() {
      return { x: null, y: () => '' }
    },
    tickSizeGrid() {
      return { x: null, y: -this.widthChart }
    },
    line() {
      return d3
        .line()
        .curve(d3.curveBasis)
        .x(d => this.scale.x(d.date))
        .y(d => this.scale.y(d.grade))
    },
    area() {
      return d3
        .area()
        .curve(d3.curveBasis)
        .x(d => this.scale.x(d.date))
        .y0(d => this.scale.y(d.grade2))
        .y1(d => this.scale.y(d.grade))
    },
    currentUrl() {
      return window.location.href
    },
  },
  methods: {
    updateWidth() {
      this.width = this.$refs.container.clientWidth
    },
  },
  directives: {
    axis(el, binding) {
      const axis = binding.arg
      const axisMethod = { x: 'axisBottom', y: 'axisLeft' }[axis]
      const scale = binding.value.scale[axis]
      const tickValues = (binding.value.tickValues || {})[axis]
      const tickFormat = (binding.value.tickFormat || {})[axis]
      const tickSize = (binding.value.tickSize || {})[axis]

      let axisGenerator = d3[axisMethod](scale)
      if (tickValues) axisGenerator.tickValues(tickValues)
      if (tickFormat) axisGenerator.tickFormat(tickFormat)
      if (tickSize) axisGenerator.tickSize(tickSize)
      d3.select(el).call(axisGenerator)
    },
  },
}
</script>

<style lang="sass">
$dashoffset: 1400

.tl-graph-plot-container
  position: relative
  svg
    fill: rgba(255, 255, 255, 0.7)
  .line
    fill: none
    stroke-width: 3px
    stroke-dasharray: $dashoffset

    &.line-enter-active,
    &.line-leave-active
      transition: stroke-dashoffset 2s
      stroke-dashoffset: 0
    &.line-enter,
    &.line-leave-to
      stroke-dashoffset: $dashoffset

  .grid
    .tick
      stroke: var(--v-grey-darken1)
      stroke-opacity: 0.3
      shape-rendering: crispEdges
    path
      stroke-width: 0
  .axis
    path,
    line
      stroke: rgba(255, 255, 255, 0.7)
      shape-rendering:  crispedges
    text
      font-size: 14px
    &__x text
        transform: rotate(-45deg) translate(-1em, -.5em)
        text-anchor: end
</style>
