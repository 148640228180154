<template>
  <div class="tl-stats-grade-graph graph-container">
    <v-banner v-if="showBanner" :tile="false" :single-line="showDemoData" class="my-2">
      <v-icon v-if="!grades.length" slot="icon" color="warning">tl-priority-high</v-icon>
      {{ $t(showDemoData ? 'dashboard.showingDemoGraph' : 'dashboard.notEnoughLogged') }}
      <template v-slot:actions v-if="!this.user.guest">
        <v-btn text color="primary" @click="showDemoData = !showDemoData">
          {{ $t(showDemoData ? 'dashboard.showMine' : 'dashboard.showDemoGraph') }}
        </v-btn>
      </template>
    </v-banner>

    <div class="d-flex legend">
      <span class="legend-item mt-2 mr-1" v-for="(grade, index) in grades" :key="grade.key">
        <span class="rect" :style="{ 'background-color': colors[index] }" />
        <span class="pl-1">{{ grade.label }}</span>
      </span>
    </div>

    <tl-grade-graph-plotter :loading="loading" :data="grades" />

    <div v-if="paginationOffsetMax > 1" class="text-center">
      <v-pagination v-model="page" :length="paginationOffsetMax" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { parseDateSafe } from '@/services/parsers'
import d3 from '@/services/d3-stripped'
import User from '@/models/User'
import tlGradeGraphPlotter from './tl-grade-graph-plotter'

export default {
  components: {
    tlGradeGraphPlotter,
  },
  props: {
    user: { type: User, default: () => {} },
  },
  data: () => ({
    loading: true,
    showDemoData: false,
    userData: {},
    colors: ['#FFFFFF', '#FF0094', '#FF4DB4', '#FF99D4'], // White, Brand normal, Brand light, Brand lighter
    paginationOffset: 0,
    paginationOffsetMax: 0,
  }),
  computed: {
    ...mapState(['climbType']),
    data() {
      return this.userData.data || []
    },
    labels() {
      return this.userData.labels || []
    },
    grades() {
      // Remove data and labels where all values are zero
      let nonZeroData = this.data.filter(d => this.labels.some(l => d[l.key] > 0))
      let nonZeroLabels = this.labels.filter(l => nonZeroData.some(d => d[l.key] > 0))
      // Clip values to lowest non-zero value:
      let minGrade = parseFloat(
        d3.min(nonZeroLabels, l => {
          return d3.min(nonZeroData, d => {
            return d[l.key] || Infinity
          })
        })
      )

      // Parse date and grade into values property on each label:
      return nonZeroLabels.map((label, index) => {
        label.minVal = parseFloat(d3.min(nonZeroData, d => d[label.key]))
        label.maxVal = parseFloat(d3.max(nonZeroData, d => d[label.key]))
        label.values = nonZeroData.map(d => ({
          date: parseDateSafe(d.date),
          grade: parseFloat(d[label.key]) || minGrade,
          grade2: minGrade,
        }))
        label.color = this.colors[index]
        return label
      })
    },
    sufficientData() {
      if (!this.grades.length) return false
      let [start, end] = d3.extent(this.grades[0].values, g => g.date)
      return this.moment.duration(end - start).asMonths() > 1
    },
    showBanner() {
      return !this.loading && (!this.grades.length || this.showDemoData)
    },
    fetchParams() {
      if (this.showDemoData) return {}
      return {
        user_id: this.user.id,
        climb_type: this.climbType,
        offset: this.paginationOffset,
      }
    },
    page: {
      get() {
        return this.paginationOffsetMax - this.paginationOffset
      },
      set(newPage) {
        this.paginationOffset = this.paginationOffsetMax - newPage
      },
    },
  },
  created() {
    this.fetchWithFallback()
  },
  watch: {
    fetchParams() {
      this.fetch()
    },
  },
  methods: {
    async fetchWithFallback() {
      await this.fetch()
      if (!this.userData || !this.userData.data.length) {
        this.showDemoData = true
        this.fetch()
      }
    },
    async fetch() {
      this.loading = true
      if (this.showDemoData || this.user.guest) {
        this.showDemoData = true
        let imported = await import('./tl-grade-graph-demo-data.js')
        await new Promise(resolve => setTimeout(resolve, 500))
        this.userData = imported.default
      } else {
        this.userData = await User.$apiCall('strengthHistory', { params: this.fetchParams, timeout: 10000 })
      }
      this.paginationOffset = this.userData.pagination.offset
      this.paginationOffsetMax = this.userData.pagination.max_offset
      this.loading = false
    },
  },
}
</script>

<style lang="sass">
.tl-stats-grade-graph.graph-container
  color: rgba(255, 255, 255, 0.7)
  .v-banner__wrapper
    border-bottom: 0
  .v-banner__actions
    margin-left: 0
  .legend
    .legend-item
      padding: 0 10px 5px 0
      .rect
        display: inline-block
        width: 15px
        height: 15px
</style>
