<template>
  <v-menu left offset-y min-width="200">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon>tl-share</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="btn in btns" :key="btn.text" @click="btn.action">
        <v-list-item-icon>
          <v-icon v-text="btn.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-t="btn.text" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { copyText } from '@/services/clipboard'

export default {
  props: {
    url: { type: String, default: null },
    msg: { type: String, default: 'TopLogger' },
  },
  computed: {
    urlWithFallback() {
      return this.url || window.location.origin + this.$route.fullPath
    },
    isiOSDevice() {
      return navigator.userAgent.match(/ipad|iphone/i)
    },
    btns() {
      let btns = [
        { text: 'dashboard.whatsapp', icon: 'tl-whatsapp', action: this.shareWhatsapp },
        { text: 'dashboard.facebook', icon: 'tl-facebook', action: this.shareFacebook },
        { text: 'dashboard.twitter', icon: 'tl-twitter', action: this.shareTwitter },
      ]
      if (!this.isiOSDevice) {
        btns.push({ text: 'dashboard.link', icon: 'tl-link', action: this.copyLink })
      }
      return btns
    },
  },
  methods: {
    shareWhatsapp() {
      let text = this.msg ? [this.msg, this.urlWithFallback].join('\n') : this.urlWithFallback
      this.openUrl(`https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`)
    },
    shareFacebook() {
      this.openUrl(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.urlWithFallback)}`)
    },
    shareTwitter() {
      this.openUrl(
        `http://twitter.com/share?url=${encodeURIComponent(this.urlWithFallback)}&text=${encodeURIComponent(this.msg)}`
      )
    },
    copyLink() {
      copyText(this.urlWithFallback)
      this.$store.dispatch('toast/success', this.$t('generic.linkCopiedMsg'))
    },
    openUrl(url) {
      if (!url) return
      let win = window.open(
        url,
        '_blank',
        'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
      )
      win.focus()
    },
  },
}
</script>
