<template>
  <v-card dark color="grey darken-4" :loading="loading ? 'primary' : false">
    <div class="d-flex flex-column align-center pa-4">
      <v-img
        v-if="user.avatar && !avatarError"
        :src="user.avatar"
        :width="$vuetify.breakpoint.xs ? 150 : 300"
        aspect-ratio="1"
        class="grey lighten-2 tl-rounded"
        @click="openImgDialog(user.avatar)"
        @error="avatarError = true"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey darken-3"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-icon v-else class="grey lighten-2 white--text tl-rounded pa-4 mt-4" :size="140" v-text="'tl-person'" />
    </div>
    <v-card-title class="d-flex justify-center text-center display-1" v-text="user.fullName" />
    <v-card-text>
      <div class="text-center pb-4" v-t="'dashboard.score.subhead'" />

      <h3 class="title white--text">
        {{ $t('dashboard.score.historyHeader', { routeBoulder: $t(`generic.${climbType}`) }) }}
      </h3>
      <span v-html="$t('dashboard.score.historySubhead')" />
      <tl-stats-grade-graph v-if="!loading" :user="user" />

      <div class="title white--text">
        {{ $t('dashboard.score.countHeader', { routesBoulders: $t(`generic.${climbType}s`) }) }}
      </div>
      {{ $t('dashboard.score.countHeaderExpl', { routesBoulders: $t(`generic.${climbType}s`), gymName: gym.name }) }}
      <tl-stats-count-bar :count="stats.count" :total="countTotal" class="my-4" />

      <div class="title white--text">
        {{ $t('dashboard.score.gradeHeader', { routeBoulder: $t(`generic.${climbType}`) }) }}
      </div>
      {{ $t('dashboard.score.gradeHeaderExpl', { gymName: gym.name }) }}
      <tl-stats-top-ten :logs="stats.top_ten" :average="stats.grade" />
      <tl-stats-grade-bar :grade="stats.grade" />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { parseFloatSafe } from '@/services/parsers'
import User from '@/models/User'
import tlStatsGradeGraph from './tl-stats-grade-graph'
import tlStatsCountBar from './tl-stats-count-bar'
import tlStatsGradeBar from './tl-stats-grade-bar'
import tlStatsTopTen from './tl-stats-top-ten'

export default {
  components: {
    tlStatsGradeGraph,
    tlStatsCountBar,
    tlStatsGradeBar,
    tlStatsTopTen,
  },
  props: {
    user: { type: User, default: () => {} },
  },
  data: () => ({
    loading: false,
    userStats: null,
    avatarError: false,
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    stats() {
      if (this.loading) return {}
      return this.userStats || this.demoStats
    },
    demoStats() {
      return {
        grade: 7.03,
        grade_count: 10,
        count: Math.round(0.75 * this.countTotal) || 0,
        top_ten: [
          /* eslint-disable */
          { checks: 1, grade_adj: 7.33, grade: 7.33, color: '#000', name: 'Guest demo dashboard' },
          { checks: 1, grade_adj: 7.17, grade: 7.17, color: '#000', name: 'The cliffhanger' },
          { checks: 1, grade_adj: 7.17, grade: 7.17, color: '#000', name: 'Fiesta de los Biceps' },
          { checks: 2, grade_adj: 7.00, grade: 7.17, color: '#000', name: 'L\'Hélicoptère' },
          { checks: 1, grade_adj: 7.00, grade: 7.00, color: '#000', name: 'Rudeboy' },
          { checks: 2, grade_adj: 6.83, grade: 7.00, color: '#000', name: 'La Marie Rose' },
          { checks: 2, grade_adj: 6.83, grade: 7.00, color: '#fff', name: 'Bon voyage' },
          { checks: 1, grade_adj: 6.83, grade: 6.83, color: '#000', name: 'The terminator' },
          { checks: 2, grade_adj: 6.67, grade: 6.83, color: '#fff', name: 'Audacieux' },
          { checks: 2, grade_adj: 6.67, grade: 6.83, color: '#fff', name: 'El Camino' },
        ], /* eslint-enable */
      }
    },
    countTotal() {
      return this.gym[`nr_of_${this.climbType}s`] || 0
    },
    fetchParams() {
      return {
        user_id: this.user.id,
        gym_id: this.gym.id,
        climbs_type: this.climbType + 's',
      }
    },
  },
  created() {
    this.fetchStats()
  },
  watch: {
    fetchParams() {
      this.fetchStats()
    },
  },
  methods: {
    async fetchStats() {
      if (this.user.guest) return
      this.loading = true
      this.userStats = await User.$apiCall('stats', { params: this.fetchParams })
      this.userStats.grade = parseFloatSafe(this.userStats.grade)
      this.loading = false
    },
    openImgDialog(image) {
      const component = () => import('@/components/shared/tl-img-dialog.vue')
      this.$store.dispatch('dialog/open', { component, props: { images: [image], maxWidth: 800 } })
    },
  },
}
</script>
