import store from '@/store'
import { combineRoutes } from '@/services/utils'

export default function(to, from, next) {
  let uidUser = store.state.user.uid
  let toCurrentUser = combineRoutes(to, { query: { uid: uidUser }, replace: true })
  if (!to.query.uid && uidUser) {
    return next(toCurrentUser)
  }
  next()
}
