import { mapState, mapMutations } from 'vuex'
import User from '@/models/User'

export default {
  computed: {
    ...mapState(['userViewedLoading']),
  },
  watch: {
    '$route.query.uid': {
      immediate: true,
      handler(uid) {
        if (!uid) {
          this.setUserViewed(null) // The getter will fallback to the current user
        } else {
          if (this.userViewedLoading) return
          // Try to find in local store:
          this.setUserViewed(User.findBy('uid', uid))
          if (this.$store.state.userViewed) return
          // Fetch from API store
          this.setUserViewedLoading(true)
          User.$apiFind(uid)
            .then(this.setUserViewed)
            .catch(e => {
              if (e && e.response && e.response.status == 401) {
                this.$router.replaceSmart({ query: { uid: null } })
              }
            })
            .finally(() => {
              this.setUserViewedLoading(false)
            })
        }
      },
    },
  },
  methods: {
    ...mapMutations(['setUserViewed', 'setUserViewedLoading']),
  },
}
