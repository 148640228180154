<template>
  <div class="tl-stats-count__bar">
    <div class="tl-stats-count__progress" :style="{ width: percent + '%' }">
      <span class="tl-stats-count__percent" v-if="percent > 50">{{ percent }}%</span>
      <span v-if="percent < 95" class="tl-stats-count__count" :class="{ 'tl-stats-count__count--small': percent < 25 }">
        {{ count }}
      </span>
    </div>
    <span class="tl-stats-count__total">
      <span v-if="percent > 95">{{ $t('dashboard.score.countBarText', { count }) }}</span>
      {{ total }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    count: { type: Number, default: 0 },
    total: { type: Number, default: 250 },
  },
  computed: {
    percent() {
      return Math.round((this.count / this.total) * 100)
    },
  },
}
</script>

<style lang="sass">
$radius: 4px

.tl-stats-count
  &__bar
    position: relative
    width: 100%
    min-height: 40px
    background-color: var(--v-primary-lighten4)
    border-radius: $radius
    color: white
    font-weight: bold
  &__progress
    position: absolute
    height: 100%
    background-color: var(--v-primary-base)
    border-top-left-radius: $radius
    border-bottom-left-radius: $radius
    color: white
    font-weight: bold
  &__percent
    @include tl-center
  &__count
    position: absolute
    top: 50%
    right: 8px
    transform: translate(0,-50%)
    &--small
      right: -8px
      transform: translate(100%,-50%)
  &__total
    position: absolute
    top: 50%
    right: 8px
    transform: translateY(-50%)
</style>
