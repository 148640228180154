<template>
  <v-card outlined :loading="loading">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="v-card__title">{{ header }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn icon @click="fetch"><v-icon>tl-autorenew</v-icon></v-btn>
      </v-list-item-action>
    </v-list-item>
    <v-row>
      <v-col cols="12" lg="6">
        <v-timeline class="mr-4" dense>
          <v-timeline-item fill-dot small icon="tl-arrow-downward">
            <h2 class="display-1 font-weight-light" v-t="'dashboard.oldNew.lastIn'" />
            <v-divider />
          </v-timeline-item>
          <v-timeline-item v-if="!lastIn.length" fill-dot small color="grey lighten-2">
            <span v-t="'dashboard.oldNew.nothing'" />
          </v-timeline-item>
          <tl-climb-renewal v-for="item in lastIn" :key="getKey(item)" :item="item" last-in />
        </v-timeline>
      </v-col>
      <v-col cols="12" lg="6">
        <v-timeline class="mr-4" dense>
          <v-timeline-item fill-dot small icon="tl-arrow-upward">
            <h2 class="display-1 font-weight-light" v-t="'dashboard.oldNew.firstOut'" />
            <v-divider />
          </v-timeline-item>
          <v-timeline-item v-if="!firstOut.length" fill-dot small color="grey lighten-2">
            <span v-t="'dashboard.oldNew.nothing'" />
          </v-timeline-item>
          <tl-climb-renewal v-for="item in firstOut" :key="getKey(item)" :item="item" first-out />
        </v-timeline>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { capitalize } from '@/services/utils'
import Gym from '@/models/Gym'
import Climb from '@/models/Climb'
import Group from '@/models/Group'
import Wall from '@/models/Wall'
import tlClimbRenewal from './tl-climb-renewal.vue'

export default {
  components: {
    tlClimbRenewal,
  },
  data: () => ({
    loading: true,
    renewals: {},
  }),
  computed: {
    ...mapState(['gym', 'climbType']),
    header() {
      return this.$t('dashboard.oldNew.header', { routeBoulder: capitalize(this.climbType) })
    },
    lastIn() {
      if (!this.renewals.new) return []
      const news = this.renewals.new
      return news.climbs
        .concat(news.groups)
        .concat(news.walls)
        .sort((a, b) => new Date(b.date_set) - new Date(a.date_set))
    },
    firstOut() {
      if (!this.renewals.old) return []
      const olds = this.renewals.old
      return olds.climbs
        .concat(olds.groups)
        .concat(olds.walls)
        .sort((a, b) => new Date(b.date_removed) - new Date(a.date_removed))
    },
    getKey() {
      return item => `${item.constructor.name}_${item.id}`
    },
    fetchParams() {
      return {
        gym_id: this.gym.id,
        climbs_type: this.climbType + 's',
      }
    },
  },
  created() {
    this.fetch()
  },
  watch: {
    fetchParams() {
      this.fetch()
    },
  },
  methods: {
    async fetch() {
      this.loading = true
      this.renewals = await Gym.$apiCall('climbRenewals', { params: this.fetchParams, timeout: 0 }).finally(
        () => (this.loading = false)
      )
      this.inject(this.renewals)
    },
    inject(renewals) {
      Object.keys(renewals).forEach(async newOld => {
        renewals[newOld].climbs = await Climb.inject(renewals[newOld].climbs)
        renewals[newOld].walls = await Wall.inject(renewals[newOld].walls)
        renewals[newOld].groups = await Group.inject(renewals[newOld].groups)
      })
    },
  },
}
</script>
