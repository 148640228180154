<template>
  <v-timeline-item fill-dot right color="grey lighten-2" class="pb-1">
    <template v-slot:icon>
      <tl-grade-circle
        v-if="item.hold"
        :grade="item.grade"
        :color="item.hold.color"
        :color-secondary="item.hold.color_secondary"
      />
      <v-icon v-else :style="{ color: item.color }" large>tl-{{ isWall ? 'room' : 'label' }}</v-icon>
    </template>

    <v-hover v-slot:default="{ hover }">
      <v-card flat :elevation="hover && isClimb ? 2 : 0" @click="goToItem">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title v-text="title"></v-list-item-title>
            <v-list-item-subtitle v-text="description"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <span>{{ time }}</span>
          </v-list-item-action>
        </v-list-item>
      </v-card>
    </v-hover>
  </v-timeline-item>
</template>

<script>
import { mapState } from 'vuex'
import tlGradeCircle from '@/components/gym/climbs/tl-grade-circle'

export default {
  components: {
    tlGradeCircle,
  },
  props: {
    item: { type: Object, default: () => {} },
    lastIn: { type: Boolean, default: false },
    firstOut: { type: Boolean, default: false },
  },
  computed: {
    ...mapState(['climbType']),
    title() {
      return this.item.name || (this.item.wall && this.item.wall.name)
    },
    description() {
      return this.item.description || (this.item.setter && this.item.setter.name)
    },
    isWall() {
      return this.item.constructor.name == 'walls'
    },
    isClimb() {
      return this.item.constructor.name == 'climbs'
    },
    time() {
      if (this.lastIn) {
        return this.moment(this.item.date_set).fromNow()
      } else {
        if (this.moment().diff(this.item.date_removed) < 0) return ''
        return this.moment(this.item.date_removed).toNow()
      }
    },
  },
  methods: {
    goToItem() {
      if (this.isClimb)
        this.$router.pushSmart({
          name: 'climbs.climb',
          params: {
            climbId: this.item.id,
            climbsType: this.climbType + 's',
          },
        })
    },
  },
}
</script>
