export function copyElemContents(elem) {
  if (document.selection) {
    let range = document.body.createTextRange()
    range.moveToElementText(elem)
    range.select()
  } else if (window.getSelection) {
    let selection = window.getSelection()
    let range = document.createRange()
    range.selectNode(elem) // or use range.selectNodeContents(elem)
    selection.removeAllRanges()
    selection.addRange(range)
  }
  _copy()
}

export function copyText(text) {
  let textArea = document.createElement('textarea')
  textArea.value = text // eslint-disable-next-line
  textArea.setAttribute('style', `
    position: fixed;
    top: 0;
    left: 0;
    width: 2em;
    height: 2em;
    padding: 0;
    border: none;
    outline: none;
    boxShadow: none;
    background: transparent;`
  )
  document.body.appendChild(textArea)
  textArea.select()
  _copy()
  document.body.removeChild(textArea)
}

function _copy() {
  try {
    document.execCommand('copy')
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Oops, unable to copy', err)
  }
}
